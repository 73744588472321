<template>
    <main class="swcluster-main" id="swcluster-datasandbox">
        <!-- page-header -->
        <div class="page-header header-divider">
            <div class="header-component">
                <CommonBreadcrumb :show-title="true" />
                <div class="header-links">
                    <a href="javascript:" class="link text-muted" @click="goAws('quicksight')">Aws QuickSight <i class="icon-link"></i></a>
                </div>
            </div>
        </div>
        <!-- page-body -->
        <div class="page-body page-component">
            <!-- page-aside -->
            <SandboxSideBar />
            <!-- page-contents -->
            <div class="page-contents">
                <div ref="container"></div>
            </div>
            <!-- //page-contents -->
        </div>
    </main>
</template>

<script>
import {onMounted, ref} from 'vue';
import {isSuccess} from '@/assets/js/common.utils';
import ApiService from '@/assets/js/api.service';
import {goAws} from '@/assets/js/modules/aws/module';
import CommonBreadcrumb from '@/components/common/layout/CommonBreadcrumb';
import SandboxSideBar from '@/components/sandbox/SandboxSideBar';
import {embedDashboard} from 'amazon-quicksight-embedding-sdk';
import {hideLoading, showLoading} from '@/assets/js/common.alert';

export default {
    name: 'QuickSight',
    components: {SandboxSideBar, CommonBreadcrumb},
    setup: function () {
        showLoading();

        const container = ref(null);
        // const embeddedURL = ref(null);

        const getQuickSight = () => {
            // ApiService.post('/v1/aws/quicksight', {})
            ApiService.post('/v1/aws/quicksight/console', {})
                .then(res => {
                    if (isSuccess(res) && res.embeddedURL) {
                        const options = {
                            url: res.embeddedURL,
                            container: container.value,
                            scrolling: 'no',
                            height: '700px',
                            width: '920px',
                            footerPaddingEnabled: true,
                        };
                        embedDashboard(options);
                    }
                    hideLoading();
                })
                .catch(e => {
                    console.error(e);
                    hideLoading();
                });
        };

        onMounted(() => {
            getQuickSight();
        });

        return {
            container,
            goAws,
        };
    },
};
</script>
